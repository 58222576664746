var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sun-form",
    { staticClass: "text-sm", on: { submit: _vm.onNewCommentSubmit } },
    [
      _c("asterix-modal", {
        attrs: { title: _vm.title },
        on: {
          cancel: function ($event) {
            return _vm.$emit("cancel")
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "sun-label-group",
                  {
                    staticClass: "block mb-2 text-left w-full",
                    attrs: { text: "Tag" },
                  },
                  [
                    _c("sun-select", {
                      staticClass: "w-1/2",
                      attrs: {
                        options: _vm.tags,
                        "track-by": "value",
                        label: "name",
                        required: "required",
                        "text-error": "Tag is required",
                      },
                      model: {
                        value: _vm.newComment.tag,
                        callback: function ($$v) {
                          _vm.$set(_vm.newComment, "tag", $$v)
                        },
                        expression: "newComment.tag",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "sun-label-group",
                  {
                    staticClass: "block mb-5 text-left w-full",
                    attrs: { text: "Comment" },
                  },
                  [
                    _c("sun-text-area", {
                      attrs: {
                        resize: "resize-y",
                        required: "required",
                        name: "campaign-comment",
                        placeholder: "Write some notes here...",
                        value: _vm.newComment.content,
                        error: !!_vm.contentTextError,
                        "text-error": _vm.contentTextError,
                      },
                      on: {
                        change: _vm.onCommentContent,
                        blur: function ($event) {
                          return _vm.validateComment($event.value)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("div", { staticClass: "sm:px-3 sm:flex sm:flex-row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex w-full rounded-md shadow-sm sm:mr-3 sm:w-auto",
                    },
                    [
                      _c(
                        "sun-button",
                        {
                          staticClass: "w-full text-xs custom-p-1",
                          attrs: {
                            variant: "pill",
                            type: "submit",
                            color: "orange",
                            "data-test": "sendBtn",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.submitBtnText) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:w-auto",
                    },
                    [
                      _c(
                        "sun-button",
                        {
                          staticClass: "w-full text-xs custom-p-1",
                          attrs: { variant: "pill", color: "white" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("cancel")
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }