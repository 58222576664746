export const ERRORS = {
  en: {
    required: 'This field is required',
    emailNotExists: 'There is not an account for this email',
    emailResetRequested: 'The request to reset the password has already been done. Please check you email inbox.',
    tokenExpired:
      'This link to reset your password has already expired. If you want to reset your password, please request it again.',
    tokenUsed:
      'This link to reset your password has already been used. If you want to reset your password, please request it again.',
    passwordMisMatch: 'The password is not matching',
    moreSixCharacters: 'This field must be more than 6 characters',
    validEmail: 'The email field must be a valid email',
    validCif: 'The cif field must be a valid cif',
    greaterThanThreeLessFiftyChars: 'This field must be greater than 3 and less than 50 characters',
    selectOption: 'Select an option',
    notBlank: 'This field cannot be blank',
    validUrl: 'This field must be a valid url',
    notBlankOrZero: 'This field cannot be blank or zero',
    selectDate: 'Select a date',
    formContainsErrors: 'The form contains errors, check it to solve it',
    notValidJSON: `The json it's not valid`,
    errorHasOccurred: 'Oppss! An error has occurred',
    connectToAPI: 'When connecting to the API',
    fieldContainsErrors: 'The field contains errors, check it to solve it',
    initialDateMustLessThanEndDate: 'The initial date must be less than the end date',
    selectAtLeastOne: 'Select at least one.',
    campaignCommentMinLen: 'Text length must be greater than 6 chars',
    campaignCommentMaxLen: 'Text length must be lower than 2000 chars',
    campaignGroupCommentMinLen: 'Text length must be greater than 6 chars',
    campaignGroupCommentMaxLen: 'Text length must be lower than 2000 chars',
    percentageShouldBeValid: 'The percentage should be valid',
    minLength: len => `Text length must be equal or greater than ${len} chars`,
    maxLength: len => `Text length must be equal or lower than ${len} chars`,
    min: number => `The number must be equal or greater than ${number}`,
    max: number => `The number must be equal or lower than ${number}`,
    pattern: 'The field does not match a valid character pattern',
    passwordPattern:
      'The password must contain at least 8 characters, one number, one uppercase letter, one lowercase letter and one special character',
  },
};
