<template>
  <sun-form class="text-sm" @submit="onNewCommentSubmit">
    <asterix-modal :title="title" @cancel="$emit('cancel')">
      <template #content>
        <sun-label-group text="Tag" class="block mb-2 text-left w-full">
          <sun-select
            v-model="newComment.tag"
            class="w-1/2"
            :options="tags"
            track-by="value"
            label="name"
            required="required"
            text-error="Tag is required"
          />
        </sun-label-group>

        <sun-label-group text="Comment" class="block mb-5 text-left w-full">
          <sun-text-area
            resize="resize-y"
            required="required"
            name="campaign-comment"
            placeholder="Write some notes here..."
            :value="newComment.content"
            :error="!!contentTextError"
            :text-error="contentTextError"
            @change="onCommentContent"
            @blur="validateComment($event.value)"
          />
        </sun-label-group>
      </template>

      <template #footer>
        <div class="sm:px-3 sm:flex sm:flex-row">
          <div class="flex w-full rounded-md shadow-sm sm:mr-3 sm:w-auto">
            <sun-button
              variant="pill"
              type="submit"
              class="w-full text-xs custom-p-1"
              color="orange"
              data-test="sendBtn"
            >
              {{ submitBtnText }}
            </sun-button>
          </div>
          <div class="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <sun-button variant="pill" class="w-full text-xs custom-p-1" color="white" @click="$emit('cancel')">
              Cancel
            </sun-button>
          </div>
        </div>
      </template>
    </asterix-modal>
  </sun-form>
</template>

<script>
import AsterixModal from '@/components/organisms/shared/AsterixModal';
import CommentShared from '@/model/shared/CommentShared';
import { deepClone } from '@/utils/deepClone';

import { ERRORS } from '@/i18n/forms/errors';

export default {
  name: 'NewCommentModal',
  components: { AsterixModal },
  props: {
    comment: {
      type: CommentShared,
      required: false,
      default: null,
    },
    tags: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data: () => ({
    newComment: new CommentShared(),
    contentTextError: null,
  }),
  computed: {
    title() {
      return `${this.comment ? 'Update' : 'New'} comment`;
    },
    submitBtnText() {
      return this.newComment.id ? 'Update' : 'Create';
    },
  },
  beforeMount() {
    if (this.comment) {
      this.newComment = deepClone(this.comment);
    }
  },
  methods: {
    onCommentContent(event) {
      this.newComment.content = event.value;
      this.contentTextError = null;
    },
    onNewCommentSubmit(event) {
      if (this.validateComment(this.newComment.content) && event?.valid) {
        this.$emit('submit', this.newComment);
      }
    },
    validateComment(comment) {
      const len = comment.length;
      if (len && len > 5 && len < 2000) {
        this.contentTextError = null;
        return true;
      }
      if (!len || len === 0) {
        this.contentTextError = ERRORS.en.required;
      } else if (len < 6) {
        this.contentTextError = ERRORS.en.campaignCommentMinLen;
      } else {
        this.contentTextError = ERRORS.en.campaignCommentMaxLen;
      }
      return false;
    },
  },
};
</script>
